.content {
    margin: 50px 100px;
    background-color: white;
    padding: 0px 45px 45px 45px !important;
}

@media (max-width: 960px) {
    .content {
        margin: 20px;
        padding: 20px !important;
    }
}

.mainBody {
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden
}