.mail, .mail:hover {
    color: #618eba;
    text-decoration: underline;
}

.mail {
    text-decoration: none;
}

#openstreetmap {
    border: 1px solid black;
    width: inherit;
}
