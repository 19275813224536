table.easy-table {
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
}

.easy-table {
    width: 100%;
}

table {
    width: 100%;
    border: 1px solid #e0e0e0;
}

.breakfast {
    font-style: italic;
    text-align: left;
}

.tableRow:hover {
    background-color: #f5f5f5;
}

.priceTable th, td {
  border: 1px solid rgba(224, 224, 224, 1);
}

.conditions {
    margin: 15px 0px;
}

.room-link, .room-link:hover {
    color: #618eba;
    text-decoration: underline;
}

.room-link {
    text-decoration: none;
}