.active {
    background-color: #3A62F4;
    color: white;
    border-radius: 8px;
}

.link {
    padding: 10px;
    text-decoration: none;
}
.langButton {
    margin-left: auto;
}

header {
    background-color: #f1f1f1;
    padding: 20px;
  }