.active {
    background-color: #3A62F4;
    color: white;
    border-radius: 8px;
}

.link {
    padding: 10px;
}
.langButton {
    float: right;
}

.Footer {
    background-color: white;
    padding: 1px;
}